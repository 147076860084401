<template>
    <h4 class="text-2xl py-2">+90<span class="update_date">(2024.12.19)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>모뎀 업데이트 화면 오류 Hot-Fix
    </li>
  </ul>
  <h4 class="text-2xl py-2">+89<span class="update_date">(2024.12.18)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>RX Styler 무빙행어 이상 개선 FOTA PGM 업데이트
      <br>- SAA44555501 (40B7, 0D5D) -> 3F4A
    </li>
  </ul>
  <h4 class="text-2xl py-2">+88<span class="update_date">(2024.11.27)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>RX Good Online 모델 Field Issue 개선 FOTA 업데이트
      <br>- SAA44555603 (DE88) -> (DE8A)
    </li>
  </ul>
  <h4 class="text-2xl py-2">+87<span class="update_date">(2024.11.20)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내수향 XD, XO모델 내장형 FOTA PGM 업데이트
      <br>- SAA41261103 (1321, 4D4B, ACFF) -> (CBBE)
      <br>- SAA41264002 (251E, 59D0, CA87) -> (9C89)
      <br>- SAA38669725 (4054) -> (3664)
      <br>- SAA38687132 (D482, EE08) -> (A1A3)
    </li>
  </ul>
  <h4 class="text-2xl py-2">+86<span class="update_date">(2024.11.06)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>모하비(내수/홍콩) 모델 대상 체크섬 추가
      <br> - SAA44779303 (26C0)
    </li>
  </ul>
  <h4 class="text-2xl py-2">+85<span class="update_date">(2024.10.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>하이드로타워 제품 대상 체크섬 추가
      <br> - SAA44378502 (1FE1, 52D3, 60E3, 8A42, 9200, B786, DBEC, F161)
      <br> - SAA44378102 (179E, 195F, 48FD, 7E61)
    </li>
    <li>김치냉장고 Victoria 모델 SAA44608101 FOTA 진행 시 10분 이상 Writting 되는 필드 이슈 발생하여 PGM 교체</li>
  </ul>
  <h4 class="text-2xl py-2">+84 <span class="update_date">(2024.10.15)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>키친 리니어 중형/대형 FOTA PGM 188 EA 추가되었습니다.</li>
    <table class="my-5 table-auto border-collapse border border-gray-300 w-full">
      <tbody>
        <tr v-for="(row, rowIndex) in partitionedPartNumbers" :key="rowIndex">
          <td v-for="(part, index) in row" :key="index" class="border border-gray-300 px-4 py-2">
            {{ part }}
          </td>
        </tr>
      </tbody>
    </table>
  </ul>
  <h4 class="text-2xl py-2">+83 <span class="update_date">(2024.10.07)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>에어솔루션 모하비 FOTA PGM (SAA44779303, SAA44779304) 추가되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+82 <span class="update_date">(2024.07.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 식기세척기 아이콘 및 Wi-Fi 연결 시나리오 추가되었습니다.</li>
    <li>에어솔루션 모하비 제습기 FOTA PGM (SAA44779403, SAA44779404) 추가되었습니다.</li>
  </ul>
</template>
<script setup>
const partNumbers = [
  "SAA39481503", "SAA42157501", "SAA42149203", "SAA42003703", "SAA43494001",
  "SAA39481701", "SAA40341302", "SAA42309201", "SAA42138701", "SAA41412301",
  "SAA42005101", "SAA39438801", "SAA39622901", "SAA39438901", "SAA39278701",
  "SAA40365401", "SAA42309301", "SAA41143601", "SAA40086501", "SAA41490601",
  "SAA42092201", "SAA41409101", "SAA42001101", "SAA42001001", "SAA42001401",
  "SAA42001701", "SAA40489202", "SAA41266801", "SAA41121901", "SAA41122002",
  "SAA41323503", "SAA41382203", "SAA41323402", "SAA42440401", "SAA44487801",
  "SAA44488701", "SAA39081806", "SAA42309502", "SAA42309501", "SAA42294502",
  "SAA42294501", "SAA41460502", "SAA41460402", "SAA41460401", "SAA42309602",
  "SAA42309601", "SAA42445401", "SAA42037802", "SAA42037801", "SAA43043801",
  "SAA41460902", "SAA41460801", "SAA42844901", "SAA42449101", "SAA40300902",
  "SAA40300901", "SAA42466201", "SAA42109101", "SAA40678903", "SAA42593201",
  "SAA42243601", "SAA43451301", "SAA42332501", "SAA41134102", "SAA41200401",
  "SAA42466101", "SAA42109001", "SAA42490101", "SAA40500901", "SAA40665301",
  "SAA41345101", "SAA40202804", "SAA40202803", "SAA40202802", "SAA40202801",
  "SAA39208203", "SAA41254501", "SAA42722301", "SAA42720901", "SAA40171404",
  "SAA40171403", "SAA40171402", "SAA40171401", "SAA40295404", "SAA40295403",
  "SAA40295402", "SAA40295401", "SAA41183501", "SAA42721501", "SAA42330501",
  "SAA42009301", "SAA38901102", "SAA38901101", "SAA39419301", "SAA40171504",
  "SAA40171503", "SAA40171502", "SAA40171501", "SAA40341605", "SAA40341604",
  "SAA40341603", "SAA40341602", "SAA40341601", "SAA41268701", "SAA41252701",
  "SAA42721401", "SAA41268501", "SAA42627301", "SAA42722501", "SAA43672001",
  "SAA43671601", "SAA40171304", "SAA40171303", "SAA40171302", "SAA40171301",
  "SAA40508104", "SAA40508103", "SAA40508102", "SAA40508101", "SAA42721201",
  "SAA42721001", "SAA40466603", "SAA40466602", "SAA40466601", "SAA40365604",
  "SAA40365603", "SAA40365602", "SAA40365601", "SAA41385001", "SAA41761901",
  "SAA42480001", "SAA42722701", "SAA43789101", "SAA41274101", "SAA43786201",
  "SAA40638901", "SAA42501401", "SAA42174302", "SAA42174301", "SAA42174402",
  "SAA42174401", "SAA42580701", "SAA41264701", "SAA42385101", "SAA40300001",
  "SAA42766001", "SAA40627101", "SAA42385301", "SAA42627001", "SAA40642701",
  "SAA44175901", "SAA40400103", "SAA40400202", "SAA42409401", "SAA41377901",
  "SAA40308501", "SAA42157901", "SAA42331701", "SAA40722101", "SAA40862401",
  "SAA42474601", "SAA40721701", "SAA41493301", "SAA42836501", "SAA40526803",
  "SAA42331801", "SAA42834201", "SAA42122401", "SAA41803301", "SAA40182903",
  "SAA40723101", "SAA41377801", "SAA42122601", "SAA40490102", "SAA41603201",
  "SAA42135801", "SAA39344601", "SAA40093501", "SAA42834301", "SAA41528101",
  "SAA42674701", "SAA39324505", "SAA39324504", "SAA39365010", "SAA39365009",
  "SAA39365008", "SAA39365007", "SAA39365006"
];
// 파트 번호를 4개씩 묶어서 테이블의 각 행에 표시
const partitionedPartNumbers = [];
for (let i = 0; i < partNumbers.length; i += 4) {
  partitionedPartNumbers.push(partNumbers.slice(i, i + 4));
}
</script>
